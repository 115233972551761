.pdf-container {

    padding: 0px 0px 20px 0px;
    gap: 25px;
    margin: 0px 30px 0px 30px;

    background-color: white;

}


.pdf-header {
    width: Fixed (595px)px;
    height: Hug (90px)px;
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pdf-clientlogo {
    width: 150px;
    height: 120px !important;
    background: transparent !important;
}

.pdf-userlogo {
    width: 70px;
    height: 70px;
}

.pdf-heading-top {
    font-family: Lato;
    font-size: 32px;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #747474;

}

.student-info-container {
    padding: 0px, 20px, 0px, 20px;
    gap: 20px;
}

.personal-info-container {}

.info-heading {
    font-family: Lato;
    font-size: 13px;
    font-weight: 700;
    line-height: 10.8px;
    text-align: left;
    color: #747474;

}

.info-info {
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 10.8px;
    text-align: left;
    color: #000000;
}

.underline {
    border: 2px solid #e24c01 !important;
    margin-top: 35px !important;
}

.label-heading {
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    line-height: 14.4px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #F37435;

}

.tb-heading {
    font-family: Lato;
    font-size: 13px;
    font-weight: 700;
    line-height: 18.8px;
    text-align: left;
    height: 27px;

    padding-left: 10px !important;
    /* gap: 10px; */
    /* border-radius: 12px 0px 0px 0px; */
    /* opacity: 0px; */
    background-color: #F4C5B0 !important;
}

.tb-row {
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18.8px;
    text-align: left;
    height: 27px;
    padding-left: 10px !important;
    /* gap: 10px; */
    /* border-radius: 12px 0px 0px 0px; */
    /* opacity: 0px; */
}


.stripedrow {
    background-color: #FFF0EA !important;
}


.advice {
    width: Fill (555px)px;
    height: Hug (53px)px;
    padding: 10px 0px 10px 10px;
    gap: 10px;
    border: 0.7px solid #747474;

}

.advice-heading {
    font-family: Lato;
    font-size: 9px;
    font-weight: 600;
    line-height: 10.8px;
    text-align: left;

}

.advice-data {
    font-family: Lato;
    font-size: 9px;
    font-style: italic;
    font-weight: 400;
    line-height: 10.8px;
    text-align: left;
    color: #747474;


}

.disclaimer {
    font-family: Lato;
    font-size: 13px;
    font-weight: 400;
    line-height: 10.8px;
    text-align: left;

}

.greenValue {
    font-weight: 700 !important;
    color: green !important;

}

.redValue {
    font-weight: 700 !important;
    color: red !important;

}

small {
    font-size: 10px;
    text-align: center;
}

canvas{
    background-color: transparent !important;
}