

.pdf-table {
    border-collapse: collapse;
}

.pdf-table td {
    white-space: normal;
    text-wrap: wrap;

}

.pdf-header {
    max-height: 100px;
    position: relative;
    z-index: 1;
}

.svg-wave {
    position: absolute;
    z-index: -1;
    margin-top: 160px;
}

.pdf-subheading {
    color: rgb(158, 157, 157);
    font-size: 281.25%;
    font-weight: 600;
    /* text-align: center; */
}

.pdf-table {
    margin-top: 15px;
}

.table-header {
    height: 45px;
    color: rgb(0, 0, 0);
    word-spacing: 15%;
    background: rgb(0, 153, 255);
    background: linear-gradient(90deg, rgba(0, 153, 255, 1) 0%, rgba(0, 212, 255, 1) 85%);
    border: 1 solid white;
    border-radius: 250px;
}

.table-header th {
    font-size: 150%;
    font-weight: 600;
    /* text-align: center; */
    line-height: 50px
}

.table-body td {
    font-size: 137.5%;
    font-weight: 600;
    line-height: 50px;
    white-space: pre-wrap;
    width: 150px;
    text-align: left;

}

.pdf-testname {
    color: rgb(96, 171, 218);
    font-weight: 500;
}

.page-break {
    page-break-before: always;
    /* or page-break-after: always; */
}

small {
    font-size: 112.5%;
    font-weight: 500;
}

.main-container {

    /* background-color: rgb(218, 218, 218); */

}

/* .page-break {
    display: block;

    page-break-after: always;
} */

.pdf-table td {
    white-space: normal;
    text-wrap: wrap;

}

.health-card {
    font-size: 180%;
    font-weight: 700;
    height: 100px;
    /* background-color: #007bff; */
    /* width: 100%; */
    text-align: center;
    color: black;

}

.circular-img {
    width: 200px;
    /* Adjust the width and height as needed */
    height: 200px;
    border-radius: 50%;
    /* Make it a circle */
    overflow: hidden;
    /* Ensure the image doesn't overflow the circle */
}




.card-containers {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding-top: 25px;
    height: 100vh;
}

/* .card-container {
    width: 550px;
    height: 350px;
    background-color: #fff;
    border: 2px solid #007bff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  

} */

.student-info {
    font-size: 18px;
    margin-bottom: 5px;
}


.greenValue {
    font-weight: 600;
    color: green;
}

.redValue {
    font-weight: 800;
    color: red;
}

.load_img {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    z-index: 100;
}
.loding-image{
    position: absolute;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh; 
    background-color: rgba(0, 0, 0,0.3);
}







.card-container {
    width: 300px;
    min-height: 200px;
    /* height: 300px; */
    position: relative;
    background-color: white;


}

.card-top {
    position: relative;
    height: 200px;
}

.top-blue-div {
    height: 180px;
    position: absolute;
    border-bottom-right-radius: 200px;
    background-color: #007bff;
    z-index: 2;
    width: 100%;

}

.top-orange-div {
    position: absolute;
    /* z-index: 1; */
    width: 100%;
    top: 0;
    height: 200px;
    border-bottom-right-radius: 200px;
    background-color: #f5814b;
}

.card-footer {
    height: 45px;
    width: 100%;
    bottom: 0;
}

.card-footer-div1 {
    height: 35px;
    background-color: #007bff;
}

.card-footer-div2 {
    height: 10px;
    background-color: #f5814b;

}

.card-qr-code {
    position: absolute;
    width: 115px;
    height: 118px;
    /* border-radius: 80px; */
    background-color: rgb(180, 177, 177);
    z-index: 3;
    left: 30%;
    margin-top: 38%;
    border: 3px solid white;
}

.student-data {
    height: 300px;
    padding: 65px 10px 10px 25px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 25px;
}

.testimonial-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Minimum column width of 250px, and adjust as needed */
    grid-gap: 10px; /* Adjust the gap between grid items */
}

body{
    position: relative;
    
}

.maximized-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20000; /* Ensure overlay appears above other content */
}

.maximized-image {
    max-width: 90%;
    max-height: 90%;
    text-align: center;
    
    object-fit: cover; /* Ensure the image fits within the viewport */
}

.maximized-image-overlay img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}